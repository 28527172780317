jQuery(document).ready(function($) {
  /* Get visitor's IP */
  get_visitor_ip();
});

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* Get visitor's IP and country code */
function get_visitor_ip() {
  jQuery.get(
    "https://api.ipdata.co?api-key=441c9c592e0619a591742831003ba1fa93c2b7c958f08e27636806b6",
    function(response) {
      if (response.country_code) {
        var userCountry = response.country_code;
        // console.log("countryCode: " + userCountry);

        /* Check if visitor already closed the location box. Then it should remain hidden */
        loc_box_session_storage(userCountry);
      }
    },
    "jsonp"
  );
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* sessionStorage - Remember if user closed the frontend location box */
function loc_box_session_storage(userCountry) {
  jQuery(document).on("click", "footer .location-wrap .close", function(e) {
    /* Remember status "hidden" on location box */
    sessionStorage.setItem("location_status", "hidden");
    jQuery("footer .location-wrap").hide();
  });

  /* Check location box status */
  var location_status = sessionStorage.getItem("location_status");
  // console.log("location_status: " + location_status);

  if (location_status != "hidden") {
    // console.log("Running ipdata_loc_box");
    /* Handle frontend location box */
    ipdata_loc_box(userCountry);
  }
  // else {
  //   console.log("location_status already hidden");
  // }
}

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* Handling the frontend box with location message */
function ipdata_loc_box(userCountry) {
  var data = {
    action: "ipdata_loc_box_ajax",
    user_country: userCountry
  };

  jQuery.ajax({
    type: "post",
    url: adminAjaxURL,
    data: data,
    success: function(response) {
      // console.log("SUCCESS - ipdata_loc_box");

      // console.log("Data:");
      // console.log(data);

      // console.log("Response:");
      // console.log(response);
      var responseJSON = jQuery.parseJSON(response);
      // console.log(responseJSON);

      /* Show location info box if visiting from foreign country */
      if (responseJSON.status_code == "wrong_shop_location") {
        var locationBox = jQuery("footer .location-wrap");
        jQuery(locationBox).show();
        locationBox.find(".inner").html("<p>" + responseJSON.message + "</p>");
      }

      /* ----------------------------------------------------- */
    },
    /* ----------------------------------------------------- */
    error: function(response) {
      // console.log("ERROR - ipdata_loc_box");

      // console.log("Data:");
      // console.log(data);

      // console.log("Response:");
      // console.log(response);
      var responseJSON = jQuery.parseJSON(response);
      // console.log(responseJSON);
    }
    /* ----------------------------------------------------- */
  });
}
