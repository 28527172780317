/* Ajax filtering on products. Load products with selected filter on click. */
/* Used in e.g. /views/components/products/products.twig */
/* See also /app/ajax/products/filter_terms.php */

/* Call function on click */
jQuery(document).on("click", "ul.product-term-list a", function(event) {
  event.preventDefault();
  // console.log(jQuery(this).attr('data-term'))
  ajax_products_filter_terms(jQuery(this).attr("data-term"));
});

/* Function, get products with this term ID */
function ajax_products_filter_terms(termID) {
  jQuery.ajax({
    type: "POST",
    /* admin-ajax url from variable in footer.twig */
    url: adminAjaxURL,
    data: {
      action: "products_filter_terms",
      term: termID
    },
    success: function(response) {
      // console.log(termID);
      // console.log(response);
      /* Put response in this element */
      jQuery("ul.product-list").html(response);
      return false;
    }
  });
}
