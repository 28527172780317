/* On ready */
jQuery(document).ready(function($) {
  /* ----------------------------------------------------- */
  /* ----------------------------------------------------- */
  /* ----------------------------------------------------- */
  /* Run Product quantity function */
  productQuantity();

  /* ----------------------------------------------------- */
  /* ----------------------------------------------------- */
  /* ----------------------------------------------------- */
  jQuery(function($) {
    jQuery(document).on("click", ".qty-btns.default button", function(e) {
      var itemHash = jQuery(this)
        .closest(".item-wrap")
        .find(".cart_hash")
        .attr("data-hash")
        .replace(/cart\[([\w]+)\]\[qty\]/g, "$1");
      var productID = jQuery(this)
        .closest(".item-wrap")
        .attr("data-product-id");
      var productKey = jQuery(this)
        .closest(".item-wrap")
        .find("input.product_key")
        .attr("value");
      var itemQuantity = jQuery(this)
        .closest(".qty-btns")
        .find("input")
        .val();
      var itemQuantityInput = jQuery(this)
        .closest(".qty-btns")
        .find("input");
      var totalPrice = jQuery(this)
        .closest(".item-wrap")
        .find(".price .total");
      var totalCartPrice = jQuery(this)
        .closest(".cart")
        .find(".cart-price .inner-price");
      var totalCartDiscountPrice = jQuery(this)
        .closest(".cart")
        .find(".cart-price .discount-value");
      var totalShippingPrice = jQuery(this)
        .closest(".cart")
        .find(".shipping-info .shipping-value");
      var headerCart = $("header .cart-wrap");

      var data = {
        action: "cart_ajax_change_quantity",
        hash: itemHash,
        product_id: productID,
        key: productKey,
        quantity: itemQuantity
      };

      $.ajax({
        type: "POST",
        url: adminAjaxURL,
        data: data,
        beforeSend: function(response) {
          headerCart.addClass("loading-cart-sh");
        },
        complete: function(response) {
          headerCart.removeClass("loading-cart-sh");
        },
        success: function(response) {
          // console.log("SUCCESS - Ajax change quantity");

          // console.log("Data:");
          // console.log(data);

          /* ----------------------------------------- */
          /* Get response from action "cart_ajax_change_quantity" */
          // console.log("Response:");
          // console.log(response);
          var responseJSON = $.parseJSON(response);
          // console.log(responseJSON);

          /* Add back previous quantity value on input */
          if (responseJSON.error_out_of_stock) {
            itemQuantityInput.val(responseJSON.this_product_qty_in_cart);
          } else {
            /* Update total item price in DOM */
            /* Round to 2 decimals */
            var roundedProductPrice = parseFloat(
              responseJSON.line_total + responseJSON.line_tax
            ).toFixed(2);

            /* Replace . with , if on Swedish site */
            if (jQuery("body.site-1").length) {
              roundedProductPrice = roundedProductPrice.replace(".", ",");
            }

            totalPrice.html(roundedProductPrice);

            /* Calculate total cart value */
            get_totals(totalCartPrice, totalCartDiscountPrice, totalShippingPrice);
          }

          /* Count cart items */
          cartCounter();
        },
        error: function(response) {
          // console.log("ERROR - Ajax change quantity");
          // console.log("Data:");
          // console.log(data);
          // console.log("Response:");
          // console.log(response);
          // var responseJSON = $.parseJSON(response);
          // console.log(responseJSON);
        }
      });
    });
  });
});

/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* ----------------------------------------------------- */
/* Product quantity */
function productQuantity() {
  // console.log("Running function productQuantity.");

  // ----------------------------------------
  /* On .add-to-cart-form */
  jQuery(document).on("click", ".qty-btns.default button", function(e) {
    e.preventDefault();

    // console.log("default qty click");

    var downOrUp = jQuery(this).attr("class");
    var input = jQuery(this)
      .closest(".qty-btns")
      .find("input");
    var oldQuantity = jQuery(input).val();

    // console.log("Old: " + oldQuantity);

    if (downOrUp === "qty-up") {
      var newQuantity = parseFloat(oldQuantity) + 1;
    } else {
      if (oldQuantity > 1) {
        var newQuantity = parseFloat(oldQuantity) - 1;
      } else {
        newQuantity = 1;
      }
    }

    // console.log("New: " + newQuantity);

    /* Set value on quantity input */
    jQuery(input).val(newQuantity);
  });
}

/* ----------------------------------------- */
/* AJAX get total cart value */
/* TODO: Also used in src/javascripts/ajax/cart/addToCartJs.js. Combine them? */
function get_totals(totalCartPrice, totalCartDiscountPrice, totalShippingPrice) {
  var data = {
    action: "cart_ajax_get_totals"
  };

  jQuery.ajax({
    type: "POST",
    url: adminAjaxURL,
    data: data,
    success: function(response) {
      // console.log("SUCCESS - cart_ajax_get_totals");

      /* ----------------------------------------- */
      /* Get response from action "cart_ajax_get_totals" */
      // console.log("Response:");
      // console.log(response);
      var responseJSON = jQuery.parseJSON(response);
      // console.log(responseJSON);

      /* Set total cart price in DOM */
      /* Round to 2 decimals */
      // console.log("Before rounding: " + responseJSON.total);
      var roundedCartPrice = parseFloat(responseJSON.get_totals.total).toFixed(
        2
      );

      var roundedShippingPrice = (parseFloat(responseJSON.get_totals.shipping_tax) + parseFloat(responseJSON.get_totals.shipping_total)).toFixed(
        2
      );
      console.log('roundedShippingPrice', roundedShippingPrice);

      var roundedCartDiscountPrice = parseFloat(
        responseJSON.get_discount_total
      ).toFixed(2);

      /* Replace . with , if on Swedish site */
      if (jQuery("body.site-1").length) {
        roundedCartPrice = roundedCartPrice.replace(".", ",");
        roundedCartDiscountPrice = roundedCartDiscountPrice.replace(".", ",");
        roundedShippingPrice = roundedShippingPrice.replace(".", ",");
      }

      // console.log("After rounding: " + roundedCartPrice);
      totalCartPrice.html(roundedCartPrice);
      totalCartDiscountPrice.html(roundedCartDiscountPrice);
      totalShippingPrice.html(roundedShippingPrice);
    },
    error: function(response) {
      // console.log("ERROR - cart_ajax_get_totals");
      // console.log("Response:");
      // console.log(response);
      // var responseJSON = $.parseJSON(response);
      // console.log(responseJSON);
    }
  });
}
