jQuery(document).ready(function($) {
  /* Count cart items */
  cartCounter();
  /* ---------------------------------- */
  /* On click cart symbol */
  $(document).on("click", "header .cart-wrap .cart-symbol-wrap", function(ev) {
    $("header .cart-wrap .cart").toggleClass("active");
    $("header .cart-wrap .cart").removeClass("fixed");
    /* Disable this to enable click to close anywhere */
    ev.stopPropagation();

    $(document).on("click", "header .cart-wrap .cart", function(ev) {
      /* Disable this to enable click to close anywhere */
      ev.stopPropagation();
    });

    $(document).click(function() {
      /* Click anywhere to close */
      $("header .cart-wrap .cart").removeClass(["active", "fixed"]);
    });
  });

  /* ---------------------------------- */
  /* Close header cart */
  $(document).on("click", "header .cart-wrap .close", function(ev) {
    $("header .cart-wrap .cart").removeClass(["active", "fixed"]);
  });

  $(document).on("click", "header .cart-wrap .js-continue-shopping", function(ev) {
    ev.preventDefault();
    $("header .cart-wrap .cart").removeClass(["active", "fixed"]);
  });
});

/* ---------------------------------- */
/* Count cart items */
/* Add to "window" to make it globally available */
window.cartCounter = function() {
  var itemCount = 0;

  var items = jQuery("header .cart-wrap .cart .cart-items .item-wrap");
  items.each(function() {
    itemCount += parseInt(jQuery(this).find("[name=qty]").val());
  });

  jQuery("header .cart-wrap .item-counter").text(itemCount);
};
