import CookiebotEmbeds from "cookiebot-embeds";

const lang = document.documentElement.lang;

const translations = {
  "sv-SE": {
    headingText: {
      default:
        "Aktivera [REQUIRED_COOKIES] i Cookiebot för att se detta innehåll.",
      youtube:
        "Acceptera marknadsföringskakan för att spela denna YouTube video.",
    },
    cookieCategoriesTitle: {
      marketing: "Marknadsföring",
      statistics: "Statistik",
      preferences: "Preferenser",
    },
    acceptButtonText: "Acceptera cookies för denna källa",
    openCookiebotSettingsButtonText: "Öppna Cookiebot-inställningar",
  },
  "en-GB": {
    headingText: {
      default: "Activate [REQUIRED_COOKIES] in Cookiebot to view this content.",
      youtube: "Accept the marketing cookie to play this YouTube video.",
    },
    cookieCategoriesTitle: {
      marketing: "Marketing",
      statistics: "Statistics",
      preferences: "Preferences",
    },
    acceptButtonText: "Accept cookies for this source",
    openCookiebotSettingsButtonText: "Open Cookiebot settings",
  },
};

const commonSettings = {
  background: "rgba(0, 0, 0, 0.75)",
  textColor: "white",
  buttonBackgroundColor: "#046e6e",
  buttonBackgroundColorHover: "#035c5c",
  buttonTextColor: "#fff",
  gap: "12px",
  customCSS: `
    a.source {
      color: #c3d9d7;
    }
  `,
};

const CookiebotEmbedsOptions = {
  ...commonSettings,
  ...(translations[lang] || translations["en-GB"]), // Fallback to 'en-GB' if the lang is not defined
};

new CookiebotEmbeds(CookiebotEmbedsOptions);

export default {
  init() {
    // console.log('common');
  },
  finalize() {
    // console.log('common finalize');
  },
};
