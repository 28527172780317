jQuery(document).ready(function($) {
  /* Fold down answer */
  jQuery(document).on("click", ".faq-wrap .question", function(ev) {
    $(this)
      .next(".answer")
      .slideToggle(85);
    $(this)
      .find("svg.plus")
      .toggleClass("hide");
  });
});
