/* On ready */
jQuery(document).ready(function($) {
  $(document).on("click", ".cart .remove", function(e) {
    e.preventDefault();

    // console.log("Click - Ajax remove product");
    var headerCart = $("header .cart-wrap");
    var thisBtn = $(this);
    var productKey = thisBtn.attr("data-product-key");
    var productID = thisBtn.attr("data-product-id");
    var pageID = thisBtn.attr("data-page-id");
    // console.log(productKey);
    /* The product in header cart we're working with */
    var productInHeaderCart = $(".cart-items").find(
      "[data-product-id=" + productID + "]"
    );

    var data = {
      action: "cart_ajax_remove_product",
      key: productKey
    };

    $.ajax({
      type: "post",
      url: adminAjaxURL,
      data: data,
      beforeSend: function(response) {
        headerCart.addClass("loading-cart-sh");
      },
      complete: function(response) {
        headerCart.removeClass("loading-cart-sh");
      },
      success: function(response) {
        // console.log("SUCCESS - cart_ajax_remove_product");

        // console.log("Data:");
        // console.log(data);

        /* Get response from action "cart_ajax_remove_product" */
        // console.log("Response:");
        // console.log(response);
        var responseJSON = $.parseJSON(response);
        // console.log(responseJSON);

        /* ----------------------------------------- */
        /* If product is not already in cart */
        /* Get new cart AJAX HTML to show cart items */
        if (productInHeaderCart.length == 0) {
          var data = {
            action: "cart_ajax_update_cart",
            page_id: pageID
          };

          $.ajax({
            type: "post",
            url: adminAjaxURL,
            data: data,
            success: function(response) {
              // console.log("SUCCESS - cart_ajax_update_cart");

              // console.log("Data:");
              // console.log(data);

              /* Get response from action "cart_ajax_update_cart" */
              // console.log("Response:");
              // console.log(response);

              /* Add HTML to .cart-wrap, either header cart or checkout depending on page */
              jQuery("header .cart-wrap .cart-items-outer").html(response);
              jQuery(".page-template-page-checkout .cart-wrap .cart").html(
                response
              );

              /* Count cart items */
              cartCounter();
            },
            error: function(response) {
              // console.log("ERROR - cart_ajax_update_cart");

              // console.log("Data:");
              // console.log(data);

              // console.log("Response:");
              // console.log(response);
              var responseJSON = $.parseJSON(response);
              // console.log(responseJSON);
            }
          });
        }
      },
      error: function(response) {
        // console.log("ERROR - Ajax add to cart");
        // console.log("Data:");
        // console.log(data);
        // console.log("Response:");
        // console.log(response);
      }
    });
  });
});
