jQuery(document).ready(function($) {
  /* Open social share popup */
  jQuery(document).on("click", ".totally-not-social-share a.share", function(
    ev
  ) {
    $(".totally-not-social-share .popup").toggleClass("visible");
    /* Disable this to enable click to close anywhere */
    ev.stopPropagation();
  });

  jQuery(document).on("click", ".totally-not-social-share .popup", function(
    ev
  ) {
    /* Disable this to enable click to close anywhere */
    ev.stopPropagation();
  });

  $(document).click(function() {
    /* Click anywhere to close */
    $(".totally-not-social-share .popup").removeClass("visible");
  });
});
