/* On ready */
jQuery(document).ready(function ($) {
  $(document).on("click", ".single_add_to_cart_button", function (e) {
    e.preventDefault();

    // console.log("Click - Ajax add to cart");
    var headerCart = $("header .cart-wrap");
    var addBtn = $(this);
    var productID = addBtn.attr("data-product-id");
    var variationID = addBtn.attr("data-variation-id");
    var pageID = addBtn.attr("data-page-id");
    /* The product in header cart we're working with */
    var productInHeaderCart = $(".cart-items").find(
      "[data-product-id=" + productID + "]"
    );
    var productKey = productInHeaderCart.find(".product_key").val();
    var totalPrice = productInHeaderCart.find(".price .total");
    var totalCartPrice = productInHeaderCart
      .closest(".cart")
      .find(".cart-price .inner-price");
    /* Quantity input in header cart */
    var qtyUpd = productInHeaderCart.find(".qty-btns input");
    /* Quantity select dropdown value, from single product */
    var quantity = $(".select-wrap-quantity select :selected").val();
    // console.log(productID);
    // console.log(productKey);
    // console.log(variationID);
    // console.log("Header: " + JSON.stringify(productInHeaderCart));

    /* ----------------------------------------- */
    var data = {
      action: "cart_ajax_add_to_cart",
      product_id: productID,
      key: productKey,
      variation_id: variationID,
      quantity: quantity,
    };

    $.ajax({
      type: "post",
      url: adminAjaxURL,
      data: data,
      dataType: 'json',
      beforeSend: function (response) {
        addBtn.addClass("loading-sh");
        headerCart.addClass("loading-cart-sh");
      },
      complete: function (response) {
        addBtn.removeClass("loading-sh");
        headerCart.removeClass("loading-cart-sh");
        addBtn.blur();
      },
      success: function (response) {
        // console.log(response);
        if (response.error == false) {

          /* If NOT out of stock (including cart item) */
          if (response.cart_item) {
            /* append facebook pixel script in body */
            if (response.cart_item.dk_fb_pixel_script) {
              jQuery("body").append(response.cart_item.dk_fb_pixel_script);
            }

            /* Update total price in header cart DOM */
            /* Round to 2 decimals */
            if (totalPrice.length) {
              var roundedProductPrice = parseFloat(
                response.cart_item.line_total + response.cart_item.line_tax
              ).toFixed(2);

              /* Replace . with , if on Swedish site */
              if (jQuery("body.site-1").length) {
                roundedProductPrice = roundedProductPrice.replace(".", ",");
              }

              totalPrice.html(roundedProductPrice);

              /* Calculate total cart value */
              get_totals(totalCartPrice);
            }

            /* Update quantity input value in header cart DOM */
            if (qtyUpd.length) {
              qtyUpd.val(response.cart_item.quantity);
            }
          }

          /* Count cart items */
          cartCounter();

          /** Cart popup active class */
          let classes = ['active'];

          if (!jQuery('body').hasClass('single-product')) {
            classes.push('fixed');
          }

          /* ----------------------------------------- */
          /* If product is not already in cart */
          /* Get new cart AJAX HTML to show cart items */
          if (productInHeaderCart.length == 0) {
            var data = {
              action: "cart_ajax_update_cart",
              page_id: pageID,
            };

            $.ajax({
              type: "post",
              url: adminAjaxURL,
              data: data,
              success: function (responseCart) {
                // console.log("SUCCESS - cart_ajax_update_cart");

                // console.log("Data:");
                // console.log(data);

                /* Get response from action "cart_ajax_update_cart" */
                // console.log("Response:");
                // console.log(responseCart);

                /* Add HTML to .cart-wrap, either header cart or checkout depending on page */
                jQuery("header .cart-wrap .cart-items-outer").html(responseCart);
                jQuery(".page-template-page-checkout .cart-wrap .cart").html(responseCart);

                /** Display cart dropdown for 4 sec */
                jQuery("header .cart-wrap .cart").addClass(classes);
                setTimeout(function () {
                  jQuery("header .cart-wrap .cart").removeClass(classes);
                }, 4000);

                /* Count cart items */
                cartCounter();
              },
              error: function (response) {
                // console.log("ERROR - cart_ajax_update_cart");

                // console.log("Data:");
                // console.log(data);

                // console.log("Response:");
                // console.log(response);
                var responseJSON = $.parseJSON(response);
                // console.log(responseJSON);
              },
            });
          } else {
            /** Display cart dropdown for 10 sec */
            jQuery("header .cart-wrap .cart").addClass(classes);
            setTimeout(function () {
              jQuery("header .cart-wrap .cart").removeClass(classes);
            }, 3000);
          }
        } else {
          console.log(response.msg);
          // window.location.href = response.product_url;
        }
      },
      /* ----------------------------------------- */
      error: function (response) {
        // console.log("ERROR - Ajax add to cart");
        // console.log("Data:");
        // console.log(data);
        // console.log("Response:");
        // console.log(response);
        // var responseJSON = $.parseJSON(response);
        // console.log(responseJSON);
      },
    });
  });
});

/* ----------------------------------------- */
/* AJAX get total cart value */
/* TODO: Also used in src/javascripts/ajax/cart/changeQuantity.js. Combine them? */
function get_totals(totalCartPrice) {
  var data = {
    action: "cart_ajax_get_totals",
  };

  jQuery.ajax({
    type: "POST",
    url: adminAjaxURL,
    data: data,
    success: function (response) {
      // console.log("SUCCESS - cart_ajax_get_totals");

      /* ----------------------------------------- */
      /* Get response from action "cart_ajax_get_totals" */
      // console.log("Response:");
      // console.log(response);
      var responseJSON = jQuery.parseJSON(response);
      // console.log(responseJSON);

      /* Set total cart price in DOM */
      /* Round to 2 decimals */
      // console.log("Before rounding: " + responseJSON.total);
      var roundedCartPrice = parseFloat(responseJSON.get_totals.total).toFixed(
        2
      );

      /* Replace . with , if on Swedish site */
      if (jQuery("body.site-1").length) {
        roundedCartPrice = roundedCartPrice.replace(".", ",");
      }

      // console.log("After rounding: " + roundedCartPrice);
      totalCartPrice.html(roundedCartPrice);
    },
    error: function (response) {
      // console.log("ERROR - cart_ajax_get_totals");
      // console.log("Response:");
      // console.log(response);
      // var responseJSON = $.parseJSON(response);
      // console.log(responseJSON);
    },
  });
}
