jQuery(window).on("load resize", function(e) {
  /* Open menu */
  jQuery(".menu-toggle").on("click", function(evt) {
    jQuery("body").toggleClass("menu-open");
    evt.stopImmediatePropagation();
  });

  /* Close menu on big screen */
  if (jQuery(window).width() > 1359) {
    jQuery("body").removeClass("menu-open");
  }
});
