/* Get Flickity (from package.json). Includes asNavFor package. */
import Flickity from "flickity";
import "flickity-as-nav-for";
import "flickity-fullscreen";

export default {
  init() {},
  finalize() {
    /* On load */
    jQuery(window).on("load", function($) {
      var flkty = new Flickity(".main-carousel", {
        contain: true,
        adaptiveHeight: true,
        pageDots: false,
        wrapAround: true,
        fullscreen: true
      });
      var flktyNav = new Flickity(".carousel-nav", {
        asNavFor: ".main-carousel",
        cellAlign: "left",
        contain: true,
        pageDots: false,
        wrapAround: false,
        prevNextButtons: false,
        draggable: false,
        groupCells: true
      });
      /* Fullscreen on click image */
      jQuery(document).on("click", ".main-carousel .is-selected", function(e) {
        flkty.toggleFullscreen();
        jQuery(".carousel-nav").toggleClass("is-fullscreen");
      });
      setTimeout(function() {
        /* Resize after timeout to avoid height bug */
        // console.log("resizing");
        // test
        flkty.resize();
        flktyNav.resize();
      }, 140);
  
      flktyNav.on('select', function() {
        updateTransform();
      });
  
      function updateTransform() {
        const selectedItems = document.querySelectorAll('.carousel-nav .is-selected');
        selectedItems.forEach(item => {
          // Get the current transform value
          const currentTransform = item.style.transform;
      
          // Check if rotate(-90deg) is already applied to avoid duplication
          if (!currentTransform.includes('rotate(-90deg)')) {
            // Append the rotate(-90deg) transformation with !important
            item.style.setProperty('transform', `${currentTransform} rotate(-90deg)`.trim(), 'important');
          }
        });
      }
  
      // Initial call to apply rotation to the initially selected item
      updateTransform();
    });
  }
};
