jQuery(document).ready(function($) {
  // This is a hidden input field. If it has a value, it's probably a bot.
  var input_name = $(".newsletter-wrap input.name");

  $(
    'input[type="checkbox"][name="agree-policy"], .newsletter-wrap input.name'
  ).change(function() {
    if (input_name.val() == "") {
      if (this.checked) {
        jQuery("#mc-embedded-subscribe").removeAttr("disabled");
      } else {
        jQuery("#mc-embedded-subscribe").attr("disabled", true);
      }
    } else {
      jQuery("#mc-embedded-subscribe").attr("disabled", true);
    }
  });
});

jQuery("body").on("DOMSubtreeModified", "#mce-success-response", function() {
  var formInput = document.getElementById("mce-EMAIL");
  var formBtn = document.getElementById("mc-embedded-subscribe");
  var policy = document.getElementById("policy-container");
  var circleCheckmark = document.getElementById("circle-checkmark");

  formInput.style.display = "none";
  formBtn.style.display = "none";
  policy.style.display = "none";
  circleCheckmark.style.display = "block";
});
