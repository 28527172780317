jQuery(document).ready(function ($) {
    /* Add 1 to input on click up arrow */
    jQuery("#delivery-date").hide();
    jQuery(document).on("click", ".amount-up", function (e) {
        var inputAmount = $(this)
            .parent()
            .children(":first")
            .val();

        if (!inputAmount) {
            $(this)
                .parent()
                .children(":first")
                .val(1);
            $(this)
                .parent()
                .children(":first")
                .attr("data-value", 1);
        } else {
            $(this)
                .parent()
                .children(":first")
                .val(parseInt(inputAmount) + 1);
            $(this)
                .parent()
                .children(":first")
                .attr("data-value", parseInt(inputAmount) + 1);
        }
    });

    /* Remove 1 to input on click down arrow */
    jQuery(document).on("click", ".amount-down", function (e) {
        var inputAmount = $(this)
            .parent()
            .children(":first")
            .val();

        if (inputAmount > 0) {
            $(this)
                .parent()
                .children(":first")
                .val(parseInt(inputAmount) - 1);
            $(this)
                .parent()
                .children(":first")
                .attr("data-value", parseInt(inputAmount) - 1);
        }
    });

    $(".amount-input").change(function () {
        var inputAmount = $(this)
            .parent()
            .children(":first")
            .val();

        $(this)
            .parent()
            .children(":first")
            .val(inputAmount);
        $(this)
            .parent()
            .children(":first")
            .attr("data-value", inputAmount);
    });

    /* Show more articles on click on phone */
    jQuery(document).on("click", ".show-more-articles", function (e) {
        $("tbody tr").toggleClass("show-all");
    });

    jQuery(document).on("change", "#delivery-option", function (e) {
        var selVal = jQuery(this).val();

        if (selVal == "Av kund valt leveransdatum") {


            jQuery("#delivery-date").slideDown();
        } else {
            jQuery("#delivery-date").slideUp();
        }
    });
    jQuery(function () {
        $("#delivery-date").datepicker({
            dateFormat: "yy-mm-dd"
        });
    });
});
