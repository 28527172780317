import jQuery from "jquery";
import "./style.scss";

/* Rewrite the variable below to camelCase, based on the body class */
import Router from "./javascripts/util/Router";
import common from "./javascripts/routes/common";
import home from "./javascripts/routes/home";
import pageTemplatePageCheckout from "./javascripts/routes/page-template-page-checkout";
import pageTemplatePageRetailer from "./javascripts/routes/page-template-page-retailer";
import singleProduct from "./javascripts/routes/single-product";
import "./javascripts/ajax/ipdata/ipdata";
import "./javascripts/header/menu";
import "./javascripts/cart/cart";
import "./javascripts/ajax/cart/changeQuantity";
import "./javascripts/ajax/cart/addToCartJs";
import "./javascripts/ajax/cart/removeProduct";
import "./javascripts/ajax/products/filter_terms";
import "./javascripts/vendors/jscookie.min";
import "./javascripts/vendors/lazysizes.min";
import "./javascripts/flexible-content/map";
import "./javascripts/flexible-content/faq";
import "./javascripts/flexible-content/validate-newsletter";
import "./javascripts/social/social-share";
import "./javascripts/retailer/table";


/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  /** All pages */
  common,
  /** Home page */
  home,
  /** Checkout */
  pageTemplatePageCheckout,
  /* Retail form template */
  pageTemplatePageRetailer,
  /* Single Product */
  singleProduct
});

/** Load Events */
jQuery(document).ready(() => routes.loadEvents());
