export default {
  init() {
    // console.log("kassa");
  },
  finalize() {
    // console.log("kassa finalize");

    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* PayEx AJAX */
    /* On ready */
    jQuery(document).ready(function ($) {
      /* ----------------------------------------------------- */
      /* ----------------------------------------------------- */
      /* ----------------------------------------------------- */
      /* Checkout form inputs, compare user country select and site language */
      var checkoutForm = jQuery("#checkout-form");
      $(checkoutForm).on("submit", function (e) {
        e.preventDefault();
        compare_country(checkoutForm);
      });

      /* ----------------------------------------------------- */
      /* ----------------------------------------------------- */
      /* ----------------------------------------------------- */
      /* On click PayEx Checkout button */
      /* AJAX - Get consumer info */
      $(document).on("click", ".btn.payex-checkout", function (e) {
        e.preventDefault();

        /* Hide both pay buttons */
        $(".btn.no-payex, .btn.payex-checkout").slideToggle(85);

        /* Get payment menu */
        payExPaymentMenu();
      });

      /* ----------------------------------------------------- */
      /* ----------------------------------------------------- */
      /* ----------------------------------------------------- */
      /* On click PayEx buy anonymously button */
      /* AJAX */
      $(document).on("click", ".btn.no-payex", function (e) {
        e.preventDefault();

        /* Hide both pay buttons */
        $(".btn.no-payex, .btn.payex-checkout").slideToggle(85);

        /* Get anonymous payment menu */
        payExPaymentMenuAnonymously();
      });

      /* ----------------------------------------------------- */
      /* ----------------------------------------------------- */
      /* ----------------------------------------------------- */
      /* Coupon code AJAX */
      $(document).on("click", ".btn.use-coupon", function (e) {
        e.preventDefault();

        /* Use coupon */
        applyCoupon();
      });
    });

    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* FUNCTIONS */
    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* PayEx payment menu styling */
    var paymentStyling = {
      button: {
        backgroundColor: "#046E6E",
        color: "white",
      },
      secondaryButton: {
        color: "#046E6E",
        active: {
          color: "#046E6E",
        },
        hover: {
          backgroundColor: "#eaf0f0",
          color: "#188f8f",
          border: "1px solid #046E6E",
        },
      },
      input: {
        focus: {
          border: "1px solid #046E6E",
        },
      },
      link: {
        color: "#046E6E",
      },
    };

    /* AJAX - Get PayEx payment menu */
    function payExPaymentMenu() {
      /* Get values from custom checkout form. To send to PayEx AJAX. */
      var checkoutForm = jQuery("#checkout-form");

      var data = {
        action: "payex_ajax_consumers",
        email: checkoutForm.find("input[name=email]").val(),
        phone: checkoutForm.find("input[name=phone]").val(),
        country: checkoutForm.find("select.country :selected").val(),
      };

      /* ----------------------------------------------------- */
      jQuery.ajax({
        type: "post",
        url: adminAjaxURL,
        data: data,
        success: function (response) {
          // console.log("SUCCESS - View Consumer Identification"); 

          // console.log("Data:");
          // console.log(data);

          // console.log("Response:");
          // console.log(response);
          var responseJSON = jQuery.parseJSON(response);
          // console.log(responseJSON);

          /* ---------------------------------- */
          /* Get script form PayEx */
          /* Target the specific operation */
          var operation = responseJSON.operations.find(
            (o) => o.rel === "view-consumer-identification"
          );
          // console.log("Consumer operation href: " + operation.href);

          jQuery
            .getScript(operation.href)
            .done(function () {
              // console.log("getScript done, external consumer script.");

              /* Close PayEx menus on updated cart */
              jQuery(document).on(
                "click",
                ".qty-btns.default button, .cart-items .remove, .single_add_to_cart_button",
                function (e) {
                  e.preventDefault();

                  // payex.hostedView.paymentMenu().close();
                  payex.hostedView.consumer().close();
                  jQuery(".btn.no-payex, .btn.payex-checkout").show();
                }
              );

              /* ----------------------------------------------------- */
              /* ----------------------------------------------------- */
              /* ----------------------------------------------------- */
              /* Get Checkin menu from Payex */
              payex.hostedView
                .consumer({
                  container: "checkin",
                  /* On consumer identified */
                  onConsumerIdentified: function (consumerIdentifiedEvent) {
                    // console.log("onConsumerIdentified");
                    // console.log(consumerIdentifiedEvent);

                    /* ----------------------------------------------------- */
                    /* AJAX - Get PayEx payment menu */

                    /* Create a reference number. Same in WC order and in PayEx admin */
                    var payexRef = Math.floor(
                      Math.random() * 999999999999 + 9999999
                    );

                    var data = {
                      action: "payex_ajax_payment_menu",
                      consumer_profile_ref:
                        consumerIdentifiedEvent.consumerProfileRef,
                      user_agent: navigator.userAgent,
                      payexRef: payexRef,
                    };

                    jQuery.ajax({
                      type: "post",
                      url: adminAjaxURL,
                      data: data,
                      success: function (response) {
                        // console.log("SUCCESS - Payment menu");

                        // console.log("Data:");
                        // console.log(data);
                        // console.log(data.payexRef);

                        // console.log("Response checkin:");
                        // console.log(response);
                        var responseJSON = jQuery.parseJSON(response);
                        console.log(responseJSON);

                        /* ----------------------------------------------------- */
                        /* Get paymentOrder ID. Save it in WC to be able to capture payment etc. */
                        var paymentOrderId = responseJSON.paymentOrder.id;
                        // console.log("paymentOrderId: " + paymentOrderId);
                        /* ----------------------------------------------------- */

                        /* ----------------------------------------------------- */
                        /* Get PayEx payment menu script. Then load payment menu. */
                        /* Target the specific operation */
                        var operation = responseJSON.operations.find(
                          (o) => o.rel === "view-paymentorder"
                        );
                        // console.log(
                        //   "Payment operation href: " + operation.href
                        // );

                        jQuery
                          .getScript(operation.href)
                          .done(function () {
                            // console.log("getScript done, payment menu.");

                            jQuery(document).on(
                              "click",
                              ".qty-btns.default button",
                              function (e) {
                                e.preventDefault();

                                payex.hostedView.paymentMenu().close();
                                payex.hostedView.consumer().close();
                                jQuery(
                                  ".btn.no-payex, .btn.payex-checkout"
                                ).show();
                              }
                            );
                            /* ----------------------------------------------------- */
                            /* ----------------------------------------------------- */
                            /* ----------------------------------------------------- */
                            /* Prepare payment method variable */
                            var paymentMethod = "";

                            /* PayEx frontend language depending on multisite */
                            if (jQuery("body.site-1").length) {
                              /* Swedish */
                              var payLanguage = "sv-SE";
                            } else {
                              /* English */
                              var payLanguage = "en-US";
                            }

                            var checkoutForm = jQuery("#checkout-form");
                            var checkoutCountry = checkoutForm
                              .find("select.country :selected")
                              .val();
                            if (checkoutCountry == "NO") {
                              /* Norwegian */
                              var payLanguage = "nb-NO";
                            }
                            // console.log(
                            //   "PayEx frontend language: " + payLanguage
                            // );

                            /* Get payment menu from PayEx */
                            payex.hostedView
                              .paymentMenu({
                                container: "payment-menu",
                                culture: payLanguage,
                                /* When customer has selected payment method and tries to pay */
                                onPaymentCreated: function (
                                  onPaymentCreateEvent
                                ) {
                                  /* console.log("onPaymentCreateEvent");
                                  console.log(onPaymentCreateEvent); */

                                  /* Update "Payment method" variable. Use it in onPaymentCompleted() */
                                  paymentMethod =
                                    onPaymentCreateEvent.instrument;
                                  // console.log(
                                  //   "Payment method: " + paymentMethod
                                  // );

                                  /* --------------- */
                                  /* Capture and create the order. Send var for "Payment method" */
                                  capture_wc_order(
                                    paymentMethod,
                                    data.payexRef,
                                    paymentOrderId
                                  );

                                  /* --------------- */
                                  /* If Newsletter box is checked */
                                  if (
                                    jQuery("#newsletter_checkout").is(
                                      ":checked"
                                    )
                                  ) {
                                    /* Data to send to Mailchimp function. Add user to Mailchimp member list. */
                                    var email = checkoutForm
                                      .find("input[name=email]")
                                      .val();
                                    var firstName = checkoutForm
                                      .find("input[name=first_name]")
                                      .val();
                                    var lastName = checkoutForm
                                      .find("input[name=last_name]")
                                      .val();
                                    var countryField = checkoutForm.find("select.country");
                                    var country = countryField.val();
                                    mailchimp_add_member(email, firstName, lastName, country);
                                  }
                                },
                                /* On payment completed */
                                onPaymentCompleted: function (
                                  onPaymentCompletedEvent
                                ) {
                                  // console.log(
                                  //   "onPaymentCompleted. Creating WC order."
                                  // );
                                  console.log(onPaymentCompletedEvent);

                                  /* Then redirect to "Order completed" page. Timeout to give WC AJAX order creation time ot run */
                                  setTimeout(function () {
                                    window.location.replace(
                                      onPaymentCompletedEvent.redirectUrl
                                    );
                                  }, 2000);
                                },
                                onError: function (onErrorEvent) {
                                  // console.log("paymentMenu onError");
                                  // console.log(onErrorEvent);
                                },
                                /* Styling */
                                style: paymentStyling,
                              })
                              .open();
                          })
                          /* getScript - Failed loading external payment menu script */
                          .fail(function () {
                            // console.log("ERROR load payment menu script");
                          });
                        /* ----------------------------------------------------- */
                      },
                      /* ----------------------------------------------------- */
                      error: function (response) {
                        // console.log("ERROR - Payment menu");

                        // console.log("Data:");
                        // console.log(data);

                        // console.log("Response:");
                        // console.log(response);
                        var responseJSON = jQuery.parseJSON(response);
                        // console.log(responseJSON);
                      },
                      /* ----------------------------------------------------- */
                    });
                  },
                  /* ----------------------------------------------------- */
                  onShippingDetailsAvailable: function (
                    shippingDetailsAvailable
                  ) {
                    // console.log("onShippingDetailsAvailable");
                    // console.log(shippingDetailsAvailable);
                  },
                  /* ----------------------------------------------------- */
                  onBillingDetailsAvailable: function (
                    billingDetailsAvailable
                  ) {
                    // console.log("onBillingDetailsAvailable");
                    // console.log(billingDetailsAvailable);
                  },
                  /* ----------------------------------------------------- */
                  onError: function (error) {
                    // console.log("onError");
                    // console.error(error);
                  },
                  /* ----------------------------------------------------- */
                  /* Styling */
                  style: paymentStyling,
                })
                .open();
            })
            /* getScript - Failed loading external consumer script */
            .fail(function () {
              // console.log("ERROR load consumer script");
            });

          /* ----------------------------------------------------- */
        },
        /* ----------------------------------------------------- */
        error: function (response) {
          // console.log("ERROR - View Consumer Identification");
          // console.log("Data:");
          // console.log(data);
          // console.log("Response:");
          // console.log(response);
          // var responseJSON = jQuery.parseJSON(response);
          // console.log(responseJSON);
        },
        /* ----------------------------------------------------- */
      });
    }

    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* AJAX - Get PayEx anonymous payment menu */
    function payExPaymentMenuAnonymously() {
      /* Create a reference number. Same in WC order and in PayEx admin */
      var payexRef = Math.floor(Math.random() * 999999999999 + 9999999);

      var data = {
        action: "payex_ajax_payment_menu_anonymously",
        user_agent: navigator.userAgent,
        payexRef: payexRef,
      };

      jQuery.ajax({
        type: "post",
        url: adminAjaxURL,
        data: data,
        success: function (response) {
          // console.log("SUCCESS - Payment menu anonymous");

          // console.log("Data:");
          // console.log(data);
          // console.log(data.payexRef);

          // console.log("Response:");
          // console.log(response);
          var responseJSON = jQuery.parseJSON(response);
          // console.log(responseJSON);

          /* ----------------------------------------------------- */
          /* Get paymentOrder ID. Save it in WC to be able to capture payment etc. */
          var paymentOrderId = responseJSON.paymentOrder.id;
          // console.log("paymentOrderId: " + paymentOrderId);
          /* ----------------------------------------------------- */

          /* ----------------------------------------------------- */
          /* Get PayEx payment menu script. Then load payment menu. */
          /* Target the specific operation */
          var operation = responseJSON.operations.find(
            (o) => o.rel === "view-paymentorder"
          );
          // console.log("Payment operation href: " + operation.href);

          jQuery
            .getScript(operation.href)
            .done(function () {
              // console.log("getScript done, payment menu.");

              /* Close PayEx menus on updated cart */
              jQuery(document).on(
                "click",
                ".qty-btns.default button, .cart-items .remove, .single_add_to_cart_button",
                function (e) {
                  e.preventDefault();

                  payex.hostedView.paymentMenu().close();
                  // payex.hostedView.consumer().close();
                  jQuery(".btn.no-payex, .btn.payex-checkout").show();
                }
              );
              /* ----------------------------------------------------- */
              /* ----------------------------------------------------- */
              /* ----------------------------------------------------- */
              /* Prepare payment method variable */
              var paymentMethod = "";

              /* PayEx frontend language depending on multisite */
              if (jQuery("body.site-1").length) {
                /* Swedish */
                var payLanguage = "sv-SE";
              } else {
                /* English */
                var payLanguage = "en-US";
              }

              var checkoutForm = jQuery("#checkout-form");
              var checkoutCountry = checkoutForm
                .find("select.country :selected")
                .val();
              if (checkoutCountry == "NO") {
                /* Norwegian */
                var payLanguage = "nb-NO";
              }
              // console.log("PayEx frontend language: " + payLanguage);

              /* Get payment menu from PayEx */
              payex.hostedView
                .paymentMenu({
                  container: "payment-menu",
                  culture: payLanguage,
                  /* When customer has selected payment method and tries to pay */
                  onPaymentCreated: function (onPaymentCreateEvent) {
                    // console.log("onPaymentCreateEvent");
                    // console.log(onPaymentCreateEvent);

                    /* Update "Payment method" variable. Use it in onPaymentCompleted() */
                    paymentMethod = onPaymentCreateEvent.instrument;
                    // console.log("Payment method: " + paymentMethod);

                    /* -------------------- */
                    /* LIVE Capture and create the order. Send var for "Payment method" */
                    capture_wc_order(
                      paymentMethod,
                      data.payexRef,
                      paymentOrderId
                    );

                    /* -------------------- */
                    /* If Newsletter box is checked */
                    if (jQuery("#newsletter_checkout").is(":checked")) {
                      /* Data to send to Mailchimp function. Add user to Mailchimp member list. */
                      var email = checkoutForm.find("input[name=email]").val();
                      var firstName = checkoutForm
                        .find("input[name=first_name]")
                        .val();
                      var lastName = checkoutForm
                        .find("input[name=last_name]")
                        .val();
                      var countryField = checkoutForm.find("select.country");
                      var country = countryField.val();
                      mailchimp_add_member(email, firstName, lastName, country);
                    }
                  },
                  /* On payment completed */
                  onPaymentCompleted: function (onPaymentCompletedEvent) {
                    // console.log("onPaymentCompleted. Creating WC order.");
                    //console.log(onPaymentCompletedEvent);

                    /* LOCAL & STAGE  Capture and create the order. Send var for "Payment method" */
                    /* paymentMethod = onPaymentCompletedEvent.instrument;
                    capture_wc_order(
                      paymentMethod,
                      data.payexRef,
                      paymentOrderId
                    ); */
                    /* End code for local & stage */

                    /* Then redirect to "Order completed" page. Timeout to give WC AJAX order creation time ot run */
                    setTimeout(function () {
                      window.location.replace(
                        onPaymentCompletedEvent.redirectUrl
                      );
                    }, 2000);
                  },
                  /* On error */
                  onError: function (onErrorEvent) {
                    // console.log("paymentMenu onError");
                    // console.log(onErrorEvent);
                  },
                  /* Styling */
                  style: paymentStyling,
                })
                .open();

              /* TODO: refresh() with new AJAX call when changing quantity */
              // jQuery(document).on(
              //   "click",
              //   ".qty-btns.default button",
              //   function(e) {
              //     payex.hostedView.paymentMenu().refresh();
              //   }
              // );
            })
            /* getScript - Failed loading external payment menu script */
            .fail(function () {
              // console.log("ERROR load payment menu script");
            });
          /* ----------------------------------------------------- */
        },
        /* ----------------------------------------------------- */
        error: function (response) {
          // console.log("ERROR - Payment menu");

          // console.log("Data:");
          // console.log(data);

          // console.log("Response:");
          // console.log(response);
          var responseJSON = jQuery.parseJSON(response);
          // console.log(responseJSON);
        },
        /* ----------------------------------------------------- */
      });
    }

    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* AJAX - Apply coupon */
    function applyCoupon() {
      var couponCode = jQuery("input[name=coupon-code]").val();
      // console.log("Coupon code: " + couponCode);

      /* ----------------------------------------------------- */
      var data = {
        action: "checkout_ajax_apply_coupon",
        coupon_code: couponCode,
      };

      jQuery.ajax({
        type: "post",
        url: adminAjaxURL,
        data: data,
        success: function (response) {
          // console.log("SUCCESS - Coupon code");

          // console.log("Data:");
          // console.log(data);

          // console.log("Response:");
          // console.log(response);
          var responseJSON = jQuery.parseJSON(response);
          // console.log(responseJSON);

          /* Add error/success messages */
          if (responseJSON.error) {
            /* Remove previous message */
            jQuery(".coupon-message").remove();

            /* Translations */
            if (jQuery("body.site-1").length) {
              var errorMessage = "Ogiltig rabattkod.";
            } else {
              var errorMessage = "Invalid coupon.";
            }

            /* Show message */
            jQuery(".coupon-code input").before(
              '<p class="coupon-message">' + errorMessage + "</p>"
            );
          }
          if (responseJSON.success) {
            /* Remove previous message */
            jQuery(".coupon-message").remove();
            /* Reload page */
            location.reload();
          }
        },
        /* ----------------------------------------------------- */
        error: function (response) {
          // console.log("ERROR - Coupon code");

          // console.log("Data:");
          // console.log(data);

          // console.log("Response:");
          // console.log(response);
          var responseJSON = jQuery.parseJSON(response);
          // console.log(responseJSON);
        },
        /* ----------------------------------------------------- */
      });
    }

    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* Capture info for WC order, then create it with next function */
    function capture_wc_order(paymentMethod, payexRef, paymentOrderId) {
      // var paymentMethodTest = paymentMethod;
      var checkoutForm = jQuery("#checkout-form");

      /* Checkout input, user details */
      var checkoutFormInput = {
        first_name: checkoutForm.find("input[name=first_name]").val(),
        last_name: checkoutForm.find("input[name=last_name]").val(),
        email: checkoutForm.find("input[name=email]").val(),
        phone: checkoutForm.find("input[name=phone]").val(),
        address: checkoutForm.find("input[name=address]").val(),
        co: checkoutForm.find("input[name=co]").val(),
        city: checkoutForm.find("input[name=city]").val(),
        postcode: checkoutForm.find("input[name=postcode]").val(),
        country: checkoutForm.find(".country option:selected").val(),
      };
      // console.log("checkoutFormInput: " + JSON.stringify(checkoutFormInput));

      // Save PostNord service point to order ACF
      var postnordSelect = jQuery(".service-point-wrap select :selected");
      var servicePointName = postnordSelect.attr("data-name");
      var servicePointID = postnordSelect.val();
      var servicePointAddress = postnordSelect.attr("data-delivery-address");

      /* Products details */
      /* Prepare array */
      var products = [];
      /* For each item i cart; get ID and quantity to send with AJAX */
      jQuery(".checkout-wrap .cart-items .item-wrap").each(function () {
        var id = jQuery(this).find(".product_id").val();
        var qty = jQuery(this).find(".qty-btns input").val();
        products.push({
          id: id,
          qty: qty,
        });
      });
      // console.log("Products: " + JSON.stringify(products));

      /* ----------------------------------------------------- */
      /* Create WC order AJAX */
      ajax_checkout_create_wc_order(
        checkoutFormInput,
        products,
        paymentMethod,
        payexRef,
        paymentOrderId,
        servicePointName,
        servicePointID,
        servicePointAddress
      );
    }

    /* ----------------------------------------------------- */
    /* Function, create WC order */
    function ajax_checkout_create_wc_order(
      checkoutFormInput,
      products,
      paymentMethod,
      payexRef,
      paymentOrderId,
      servicePointName,
      servicePointID,
      servicePointAddress
    ) {
      var data = {
        action: "checkout_create_wc_order",
        products: products,
        checkoutFormInput: checkoutFormInput,
        paymentMethod: paymentMethod,
        payexRef: payexRef,
        paymentOrderId: paymentOrderId,
        service_point_name: servicePointName,
        service_point_id: servicePointID,
        service_point_address: servicePointAddress,
      };
      console.log("ajax_checkout_create_wc_order");
      jQuery
        .ajax({
          type: "POST",
          /* admin-ajax url from variable in footer.twig */
          url: adminAjaxURL,
          data: data,
          // dataType: "json",
          // contentType: "application/json",
          beforeSend: function (response) {
            // console.log("Ajax checkout beforeSend executed");
            // console.log(response);
            // console.log(data);
          },
        })

        /* On success */
        .success(function (response) {
          if (response == 0) {
            // console.log("Ajax checkout success");
            // console.log(response);
          }
        })

        /* On error */
        .error(function (response) {
          // console.log("Ajax checkout ERROR");
          // console.log(response);
        });
    }

    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* Checkout form inputs, compare user country select and site language */
    function compare_country(checkoutForm) {
      /* Checkout input, user details */
      var checkoutFormInput = {
        first_name: checkoutForm.find("input[name=first_name]").val(),
        last_name: checkoutForm.find("input[name=last_name]").val(),
        email: checkoutForm.find("input[name=email]").val(),
        phone: checkoutForm.find("input[name=phone]").val(),
        address: checkoutForm.find("input[name=address]").val(),
        city: checkoutForm.find("input[name=city]").val(),
        postcode: checkoutForm.find("input[name=postcode]").val(),
        country: checkoutForm.find("select.country :selected").val(),
      };
      // console.log(checkoutFormInput);

      var data = {
        action: "ajax_compare_country_checkout",
        country: checkoutFormInput["country"],
      };

      jQuery.ajax({
        type: "post",
        url: adminAjaxURL,
        data: data,
        success: function (response) {
          // console.log("SUCCESS - Checkout form inputs");

          // console.log("Data:");
          // console.log(data);

          // console.log("Response:");
          // console.log(response);
          var responseJSON = jQuery.parseJSON(response);
          // console.log(responseJSON);

          if (responseJSON.status_code == "ok_site_country") {
            // console.log("Removing PayEx disabled attr buttons");
            /* Enable PayEx section */
            jQuery(".btn.no-payex, .btn.payex-checkout").removeAttr("disabled");
            jQuery("h3.pay").removeClass("disabled");

            // Show PostNord service point select dropdown. Swedish site only.
            if (jQuery("body.site-1").length) {
              jQuery(".service-point-wrap").removeClass("hidden");
              // Run service point check, with postal code from form input
              var postalCode = checkoutForm.find("input[name=postcode]").val();
              servicePoint(postalCode);
            }

            /* Disable form inputs */
            jQuery(checkoutForm).find("input").attr("disabled", true);

            /* Disable country select */
            jQuery(checkoutForm).find("select.country").attr("disabled", true);

            /* Remove message */
            jQuery(checkoutForm).find("p.message").remove();

            /* Set low opacity on checkout form */
            checkoutForm.addClass("disabled");
            /* Show "Change details" link (reloads page). */
            checkoutForm.find(".change-details").show().css("display", "block");
          }
          if (responseJSON.status_code == "wrong_site_country") {
            // console.log("Setting PayEx disabled attr buttons");
            /* Disable PayEx section */
            jQuery(".btn.no-payex, .btn.payex-checkout").attr("disabled", true);
            jQuery("h3.pay").addClass("disabled");

            /* Remove previous message */
            jQuery(checkoutForm).find("p.message").remove();

            /* Show message */
            jQuery(checkoutForm).append(
              '<p class="message font-p-semibold">' +
              responseJSON.message +
              "</p>"
            );
          }
          /* ----------------------------------------------------- */
        },
        /* ----------------------------------------------------- */
        error: function (response) {
          // console.log("ERROR - Checkout form inputs");
          // console.log("Data:");
          // console.log(data);
          // console.log("Response:");
          // console.log(response);
          // var responseJSON = jQuery.parseJSON(response);
          // console.log(responseJSON);
        },
        /* ----------------------------------------------------- */
      });
    }

    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* Data to send to Mailchimp AJAX. Add user to Mailchimp member list. */
    function mailchimp_add_member(email, firstName, lastName, countryValue) {
      var data = {
        action: "add_mailchimp_member_ajax",
        email: email,
        first_name: firstName,
        last_name: lastName,
        country: countryValue,

      };

      jQuery.ajax({
        type: "post",
        url: adminAjaxURL,
        data: data,
        success: function (response) {
          // console.log("SUCCESS - add_mailchimp_member_ajax");
          // console.log("Data:");
          // console.log(data);
          // console.log("Response:");
          // console.log(response);
          // var responseJSON = jQuery.parseJSON(response);
          // console.log(responseJSON);
        },
        /* ----------------------------------------------------- */
        error: function (response) {
          // console.log("ERROR - add_mailchimp_member_ajax");
          // console.log("Data:");
          // console.log(data);
          // console.log("Response:");
          // console.log(response);
          // var responseJSON = jQuery.parseJSON(response);
          // console.log(responseJSON);
        },
        /* ----------------------------------------------------- */
      });
    }

    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* ----------------------------------------------------- */
    /* AJAX - Service point */
    /* Postal code comes from checkout form */
    function servicePoint(postalCode) {
      // console.log("Postal code: " + postalCode);

      /* ----------------------------------------------------- */
      var data = {
        action: "checkout_ajax_service_point",
        postal_code: postalCode,
      };

      jQuery.ajax({
        type: "post",
        url: adminAjaxURL,
        data: data,
        success: function (response) {
          // console.log("Data:");
          // console.log(data);

          // console.log("Response:");
          // console.log(response);

          var postnordSelect = jQuery(".service-point-wrap select");

          // Error messages
          if (
            response.status_code === "error" ||
            response.status_code === "bad_postal_code"
          ) {
            /* Show message */
            jQuery(".service-point-wrap").append(
              '<p class="message red font-p-semibold">' +
              response.message +
              "</p>"
            );
          }

          // Success
          if (
            response.status_code != "error" &&
            response.status_code != "bad_postal_code"
          ) {
            /* Remove "Loading..." option */
            postnordSelect.find("option").remove();

            //Loop through service points and add them to select
            jQuery.each(response, function (key, value) {
              postnordSelect.append(
                jQuery("<option></option>")
                  .attr("value", value.servicePointId)
                  .attr("data-name", value.name)
                  .attr(
                    "data-delivery-address",
                    value.deliveryAddress.streetName +
                    " " +
                    value.deliveryAddress.streetNumber +
                    " " +
                    value.deliveryAddress.postalCode +
                    " " +
                    value.deliveryAddress.city +
                    " " +
                    value.deliveryAddress.countryCode
                  )
                  .text(
                    value.name +
                    " - " +
                    value.visitingAddress.streetName +
                    " " +
                    value.visitingAddress.streetNumber +
                    ", " +
                    value.visitingAddress.city
                  )
              );
            });

            // Set first service points as selected
            postnordSelect.prop("selectedIndex", 0);

            // Remove disabled attribute
            postnordSelect.removeAttr("disabled");
          }
        },
        /* ----------------------------------------------------- */
        error: function (response) {
          // console.log("ERROR - Coupon code");
          // console.log("Data:");
          // console.log(data);
          // console.log("Response:");
          // console.log(response);
          // var responseJSON = jQuery.parseJSON(response);
          // console.log(responseJSON);
        },
        /* ----------------------------------------------------- */
      });
    }
  },
};
